window.addEventListener("popstate", (event) => {
  if (event.state && event.state.show_results) {
    const transcriptionsSearchResult = document.getElementById(`${event.state.turbo_frames_prefix}-transcriptions-search-result`);
    if (transcriptionsSearchResult) { transcriptionsSearchResult.innerHTML = "" }
    document.getElementById(`${event.state.turbo_frames_prefix}-transcriptions-search-form`)?.classList.remove("hidden");

    const hadithsSearchResult = document.getElementById("hadiths-search-result");
    if (hadithsSearchResult) { hadithsSearchResult.innerHTML = "" }
    document.getElementById("hadiths-search-form")?.classList.remove("hidden");

    const shamelaSearchResult = document.getElementById("shamela-search-result");
    if (shamelaSearchResult) { shamelaSearchResult.innerHTML = "" }
    document.getElementById("shamela-search-form")?.classList.remove("hidden");

    document.getElementById("transcriptions-return-to-results")?.classList.add("!hidden");
    document.getElementById("hadiths-return-to-results")?.classList.add("!hidden");
    document.getElementById("shamela-return-to-results")?.classList.add("!hidden");
  } else if (event.state && event.state.turbo_stream_history) {
    Turbo.visit(window.location.href, { action: "replace" });
  }
});
