import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hadiths--post-search-results-render"
export default class extends Controller {
  connect() {
    const observer = new MutationObserver((_mutations, observer) => {
      const semanticSearchResults = document.getElementById('semantic-search-results');

      if (semanticSearchResults && semanticSearchResults.children.length > 0 && semanticSearchResults.dataset.state === 'active') {
        const scrollPosition = this.element.getBoundingClientRect().top + window.scrollY - 70;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });

        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true, attributes: true, attributeFilter: ['data-state'] });
  }
}
