import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cue-bookmarks"
export default class extends Controller {
  connect() {
    JSON.parse(document.getElementById("cue-bookmarks-data").dataset.cueIds).forEach(function (cueId) {
      Array.from(document.getElementsByClassName(`cue_${cueId}-bookmark`)).forEach(function (bookmarkElement) {
        bookmarkElement.innerHTML = `\
<a data-turbo-method="post" href="/cues/${cueId}/unbookmark"> \
  <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 text-gray-800 dark:text-white ms-3 max-sm:me-1 max-w-5"> \
    <path stroke-linecap="round" stroke-linejoin="round" d="m3 3 1.664 1.664M21 21l-1.5-1.5m-5.485-1.242L12 17.25 4.5 21V8.742m.164-4.078a2.15 2.15 0 0 1 1.743-1.342 48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185V19.5M4.664 4.664 19.5 19.5" /> \
  </svg> \
</a> \
`
      });
    });
  }
}
