import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shamela--tashkeel"
export default class extends Controller {
  static targets = ["content", "originalContent", "toggle"];

  static values = {
    show: String,
    hide: String,
  }

  connect() {
    this.showen = true;
  }

  toggle() {
    console.log(1)
    if (this.showen == true) {
      const getTextNodes = (el) => {
        let textNodes = [];

        if (el.nodeType === Node.TEXT_NODE) {
          textNodes.push(el);
        } else {
          for (const child of el.childNodes) {
            textNodes = textNodes.concat(getTextNodes(child));
          }
        }

        return textNodes;
      };

      const textNodes = getTextNodes(this.contentTarget);

      for (const node of textNodes) {
        if (node.textContent.match(/[៖ـ\u0600-\u06FF]/g)) {
          node.textContent = node.textContent.replace(/[ًٌٍَُِّْ]+/g, "");
        }
      }

      this.toggleTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="size-4"><path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/><circle cx="12" cy="12" r="3"/></svg>

        <span>${this.showValue}</span>
      `;
    } else {
      this.contentTarget.innerHTML = this.originalContentTarget.innerHTML;

      this.toggleTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="size-4"><path d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/><path d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/><path d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/><path d="m2 2 20 20"/></svg>

        <span>${this.hideValue}</span>
      `;
    }

    this.showen = !this.showen;
  }
}
