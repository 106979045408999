import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="return-to-search-results"
export default class extends Controller {
  static values = {
    idPrefix: String
  }

  webReturnToResults() {
    history.back();
  }

  nativeReturnToResults() {
    document.getElementById(`${this.idPrefixValue}-return-to-results`).classList.add("!hidden");

    if (this.idPrefixValue === "transcriptions") {
      const elements = ['home', 'speaker', 'playlist', 'medium'];

      elements.forEach(element => {
        const transcriptionsSearchResult = document.getElementById(`${element}-transcriptions-search-result`);
        if (transcriptionsSearchResult) { transcriptionsSearchResult.innerHTML = "" }
        document.getElementById(`${element}-transcriptions-search-form`)?.classList.remove("hidden");
      });
    } else {
      document.getElementById(`${this.idPrefixValue}-search-result`).innerHTML = "";
      document.getElementById(`${this.idPrefixValue}-search-form`).classList.remove("hidden");
    }
  }
}
