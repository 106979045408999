import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direction-validator"
export default class extends Controller {
  static values = {
    rtlLanguages: Array,
    ltrLanguages: Array
  }

  connect() {
    const htmlElement = document.documentElement;
    const url = window.location.href;

    if (this.#isRtlLanguage(url)) {
      htmlElement.setAttribute('dir', 'rtl');
    } else if (this.#isLtrLanguage(url)) {
      htmlElement.setAttribute('dir', 'ltr');
    }
  }

  #isRtlLanguage(url) {
    for (const language of this.rtlLanguagesValue) {
      if (url.endsWith(`/${language}`) || url.includes(`/${language}/`)) {
        return true;
      }
    }

    return false;
  }

  #isLtrLanguage(url) {
    for (const language of this.ltrLanguagesValue) {
      if (url.endsWith(`/${language}`) || url.includes(`/${language}/`)) {
        return true;
      }
    }

    return false;
  }
}
