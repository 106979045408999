window.addEventListener("turbo:frame-render", (event) => {
  if (event.target.id.endsWith("-transcriptions-search-result")) {
    const returnToResultsElement = document.getElementById("transcriptions-return-to-results");

    returnToResultsElement.classList.remove("!hidden");

    document.getElementById(event.target.id).classList.remove("hidden");
    document.getElementById(`${event.target.id.replace("-transcriptions-search-result", "")}-transcriptions-search-form`).classList.add("hidden");

    const scrollPosition = returnToResultsElement.getBoundingClientRect().top + window.scrollY - 70;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  } else if (event.target.id.endsWith("-search-result")) {
    const service = event.target.id.split("-")[0];

    if (document.getElementById(`${service}-return-to-results`)) {
      const returnToResultsElement = document.getElementById(`${service}-return-to-results`);

      returnToResultsElement.classList.remove("!hidden");

      document.getElementById(`${service}-search-result`).classList.remove("hidden");
      document.getElementById(`${service}-search-form`).classList.add("hidden");

      if (document.querySelector('meta[name="request-type"]').content === 'web') {
        Array.from(document.getElementById(`${service}-search-result`).getElementsByClassName(`${service}-navigation-link`)).forEach(function (link) {
          link.dataset.turboAction = "replace";
        });
      }

      const scrollPosition = returnToResultsElement.getBoundingClientRect().top + window.scrollY - 70;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
});
