import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="playlists--index--form"
export default class extends Controller {
  static targets = ["filters", "openFilters", "closeFilters"]

  toggleFilters() {
    this.filtersTarget.classList.toggle("flex");
    this.filtersTarget.classList.toggle("hidden");
    this.openFiltersTarget.classList.toggle("hidden");
    this.closeFiltersTarget.classList.toggle("hidden");
  }
}
