// Entry point for the build script in your package.json
import * as Turbo from '@hotwired/turbo'

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import "@hotwired/turbo-rails"
import "./controllers"

import "./custom_turbo_events"
import gtag from "./google_analytics"
import "./local_time_settings"
import "./popstate_override"
import "./ruby_ui"
