import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"
export default class extends Controller {
  static values = {
    placeholder: String,
    noResults: String,
    searchPlaceholder: String
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: this.placeholderValue,
        searchText: this.noResultsValue,
        searchPlaceholder: this.searchPlaceholderValue,
        hideSelected: true,
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}
