import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="github-from-youtube"
export default class extends Controller {
  static targets = ["player", "scrollingCue", "rotatingCue", "scrollingCuesContainer"];
  activeCueIndex = -1;

  connect() {
    this.playerTarget.innerHTML = `<audio id="github-from-youtube-player" class="w-full px-4" src="${this.data.get("link")}?raw=true" type="audio/webm" controls></audio>`;

    this.audio = document.getElementById("github-from-youtube-player");
    this.initialCueStartTime = parseInt(document.getElementById("initial-cue-data").dataset.start);
    this.activeCueIndex = this.scrollingCueTargets.findIndex((cue) => parseInt(cue.dataset.endTime) >= this.initialCueStartTime);
    this.#activateCue(this.activeCueIndex);

    this.audio.addEventListener("loadedmetadata", () => { this.audio.currentTime = this.initialCueStartTime; this.audio.play(); });
    this.audio.addEventListener("timeupdate", () => { this.#updateSubtitles(); });

    const setScrollingCuesContainerHeight = () => {
      if (this.playerTarget.offsetHeight != 0) {
        this.updateScrollingCuesContainerHeight();
      } else {
        setTimeout(setScrollingCuesContainerHeight, 100);
      }
    };

    setScrollingCuesContainerHeight();
  }

  disconnect() {
    this.audio.remove();
    this.playerTarget.innerHTML = "";
  }

  seekToSecond(event) {
    event.preventDefault();

    document.getElementById("medium-transcriptions-search-results").innerHTML = "";

    this.audio.currentTime = event.target.closest("a").dataset.startTime;
    this.audio.play();

    const newUrl = window.location.href.replace(window.location.search, "");
    const newParams = new URLSearchParams({ cue: event.target.closest("a").dataset.cueId }).toString();

    history.replaceState(history.state, "", `${newUrl}?${newParams}`);
  }

  updateScrollingCuesContainerHeight() {
    this.scrollingCuesContainerTarget.style.height = `${this.playerTarget.offsetHeight - 68}px`;
  }

  #updateSubtitles() {
    let currentCueEndTime = parseInt(this.scrollingCueTargets[this.activeCueIndex].dataset.endTime);
    let playerCurrentTime = this.audio.currentTime;

    if (Math.abs(playerCurrentTime - currentCueEndTime) > 1) {
      var updated = false;

      for (var i = 0; i < this.scrollingCueTargets.length; ++i) {
        if (!updated && parseInt(this.scrollingCueTargets[i].dataset.endTime) >= playerCurrentTime) {
          updated = true;
          this.#activateCue(i);
        } else {
          this.#deactivateCue(i);
        }
      }
    } else if (playerCurrentTime >= currentCueEndTime) {
      this.#deactivateCue(this.activeCueIndex);
      ++this.activeCueIndex;
      this.#activateCue(this.activeCueIndex, 'smooth');
    }
  }

  #activateCue(cueIndex, behavior = 'instant') {
    this.rotatingCueTargets[cueIndex].classList.add("flex");
    this.rotatingCueTargets[cueIndex].classList.remove("hidden");

    this.scrollingCueTargets[cueIndex].classList.add(...["rounded-lg", "bg-accent"]);
    this.scrollingCuesContainerTarget.scrollTo({
      top: this.scrollingCueTargets[cueIndex].offsetTop - this.scrollingCuesContainerTarget.offsetTop - 1 - 192 + (this.scrollingCueTargets[cueIndex].offsetHeight / 2),
      behavior
    })

    this.activeCueIndex = cueIndex;
  }

  #deactivateCue(cueIndex) {
    this.rotatingCueTargets[cueIndex].classList.add("hidden");
    this.rotatingCueTargets[cueIndex].classList.remove("flex");

    this.scrollingCueTargets[cueIndex].classList.remove(...["rounded-lg", "bg-accent"]);
  }
}
